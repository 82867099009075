<template>
  <v-main class="cppsbg">
    <v-app-bar class="ukpl-main-navigation" v-if="currentUser" dense color="#0072ff" dark elevate-on-scroll>
      <div class="ukpl-main-navigation-container">
        <div class="navigation-left-wrap">
          <a href="https://www.ukpl.co.uk">
            <v-img src="@/assets/ukpl-logo-new.png" alt="UKPL logo" contain class="ukpl-nav-logo" />
          </a>
        </div>

        <v-spacer></v-spacer>

        <v-toolbar-items class="hidden-sm-and-down">
          <v-btn v-if="isAdmin" color="#0072ff" depressed to="/admin">admin</v-btn>
          <v-btn color="#0072ff" depressed to="/team"><v-icon class="mr-2">fa-users</v-icon>Teams</v-btn>
          <v-btn v-if="currentCaptain" color="#0072ff" depressed to="/roster"><v-icon
              class="mr-2">fa-list</v-icon>Roster</v-btn>
          <v-btn color="#0072ff" depressed to="/profile"><v-icon class="mr-2">fa-user</v-icon>Profile</v-btn>
          <v-btn color="#0072ff" depressed to="/disclaimer"><v-icon
              class="mr-2">fa-file-signature</v-icon>Disclaimer</v-btn>
          <v-btn color="#0072ff" depressed to="/idcard"><v-icon class="mr-2">far fa-id-card</v-icon>ID Card</v-btn>
          <v-btn color="#0072ff" depressed @click.native="logOut"><v-icon
              class="mr-2">fa-sign-out-alt</v-icon>LogOut</v-btn>
        </v-toolbar-items>
      </div>

      <v-toolbar-items class="hidden-md-and-up">
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      </v-toolbar-items>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item v-if="isAdmin" key="admin" to="/admin">
            <v-list-item-title>Admin</v-list-item-title>
          </v-list-item>
          <v-list-item key="team" to="/team">
            <v-list-item-icon><v-icon>fa-users</v-icon></v-list-item-icon>
            <v-list-item-title>Teams</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="currentCaptain" key="roster" to="/roster">
            <v-list-item-icon><v-icon>fa-list</v-icon></v-list-item-icon>
            <v-list-item-title>Roster</v-list-item-title>
          </v-list-item>
          <v-list-item key="profile" to="/profile">
            <v-list-item-icon><v-icon>fa-user</v-icon></v-list-item-icon>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>
          <v-list-item key="disclaimer" to="/disclaimer">
            <v-list-item-icon><v-icon>fa-file-signature</v-icon></v-list-item-icon>
            <v-list-item-title>Disclaimer</v-list-item-title>
          </v-list-item>
          <v-list-item key="id-card" to="/idcard">
            <v-list-item-icon><v-icon>far fa-id-card</v-icon></v-list-item-icon>
            <v-list-item-title>ID Card</v-list-item-title>
          </v-list-item>
          <v-list-item @click.native="logOut" key="icon">
            <v-list-item-icon><v-icon>fa-sign-out-alt</v-icon></v-list-item-icon>
            <v-list-item-title>LogOut</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-sheet class="ukpl-blue-card-container" id="scrolling-techniques-7" height="100%">
      <v-container fluid height="100%">
        <v-row justify="center" align="center" class="row">
          <v-col cols="6" sm="6" md="6" xs="6" class="text-center">
            <v-text-field label="First Name" v-model="userDetails.firstname" disabled />
          </v-col>
          <v-col cols="6" sm="6" md="6" xs="6" class="text-center">
            <v-text-field label="Last Name" v-model="userDetails.lastname" disabled />
          </v-col>
        </v-row>
        <v-row justify="center" align="center" class="row" v-if="paydisabled">
          <v-col cols="12" sm="12" md="12" xs="12" class="text-center">
            <v-banner>
              <v-avatar slot="icon" color="deep-purple accent-4" size="40">
                <v-icon icon="mdi-account-alert" color="white">
                  mdi-account-alert
                </v-icon>
              </v-avatar>
              Payments are currently disabled for the year. You will be able to
              pay for next year soon.
            </v-banner>
          </v-col>
        </v-row>
        <v-row justify="center" align="center" class="row" v-if="!userComplete && !paydisabled && !loading">
          <v-col cols="12" sm="12" md="12" xs="12" class="text-center">
            <v-banner>
              <v-avatar slot="icon" color="deep-purple accent-4" size="40">
                <v-icon icon="mdi-account-alert" color="white">
                  mdi-account-alert
                </v-icon>
              </v-avatar>
              Please go to the Profile page and make sure you have completed all
              details, including UKPSF number, Division, and Team, and uploaded
              a valid photograph. Also please ensure you have signed the
              disclaimer on the disclaimer page.
            </v-banner>
          </v-col>
        </v-row>
        <v-row justify="center" align="center" class="row" v-if="userComplete && !paydisabled && !loading">
          <v-col cols="12" sm="12" md="12" xs="12" class="text-center">
            <div class="d-flex flex-column justify-space-between align-center">
              <div>
                Once purchased, if it is your first ID card registration, then you will collect your card from the CPPS
                shop at your next event. If you&apos;re paying to renew your ID card for this season, once paid and
                activated, you will retain and continue to use your existing card.
              </div>
              <br />
              <v-img src="@/assets/card-sample.png" max-width="250"></v-img>
              <div v-if="userDetails.cardpurchase === 0">
                Purchase Member Card (£20 GBP)
              </div>
              <div v-else>
                Purchase Replacement Member Card (Lost or Stolen) (£5 GBP)
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row v-show="userComplete && !paydisabled" justify="center" align="center" class="row">
          <div id="paypal-button-container"></div>
        </v-row>
      </v-container>
    </v-sheet>
  </v-main>
</template>

<script>
import UserService from "../services/user.service";
import UserDetails from "../model/userdetails";
import UtilService from "../services/util.service";
import { loadScript } from "@paypal/paypal-js";

export default {
  name: "IdCard",
  components: {},
  data: () => ({
    paydisabled: false,
    drawer: false,
    userDetails: new UserDetails(),
    currentCaptain: false,
    currentyear: 0,
    selectdiv: undefined,
    selectteam: undefined,
    loading: true,
  }),
  computed: {
    chargeValue() {
      if (this.userDetails.cardpurchase > 0) {
        return 5;
      } else {
        return 20;
      }
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    isAdmin() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_ADMIN");
      }
      return false;
    },
    userComplete() {
      if (
        this.isPopulated(this.userDetails.username) &&
        this.isPopulated(this.userDetails.firstname) &&
        this.isPopulated(this.userDetails.lastname) &&
        this.isPopulated(this.userDetails.gender) &&
        this.isPopulated(this.userDetails.dateOfBirth) &&
        this.isPopulated(this.userDetails.contactNumber) &&
        this.isPopulated(this.userDetails.addressline1) &&
        this.isPopulated(this.userDetails.town) &&
        this.isPopulated(this.userDetails.postcode) &&
        this.isPopulated(this.userDetails.ukpsf) &&
        this.isPopulated(this.selectdiv) &&
        this.isPopulated(this.selectteam) &&
        this.isPopulated(this.userDetails.file) &&
        this.isPopulated(this.userDetails.disclaimer) &&
        this.userDetails.disclaimer === true
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    initCurrentYear() {
      UtilService.getCurrentYear().then(
        (response) => {
          this.currentyear = response.data.year;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    isCaptain() {
      UserService.isTeamCaptain().then(
        (response) => {
          if (response.data) {
            this.currentCaptain = true;
          } else {
            this.currentCaptain = false;
          }
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    isPopulated(userfield) {
      if (
        typeof userfield !== "undefined" &&
        userfield !== "" &&
        userfield != null
      ) {
        return true;
      } else {
        return false;
      }
    },
    reloadTeams(sel) {
      this.initializeTeams(sel.id);
    },
    logOut() {
      console.log("logging out");
      this.$store.dispatch("auth/logout");
      this.$router.push("/home");
    },
    initUser() {
      UserService.getUserDetails().then(
        (response) => {
          this.userDetails.username = response.data.username;
          this.userDetails.email = response.data.email;
          this.userDetails.password = response.data.password;
          this.userDetails.firstname = response.data.firstname;
          this.userDetails.lastname = response.data.lastname;
          this.userDetails.gender = response.data.gender;
          this.userDetails.dateOfBirth = response.data.dateOfBirth;
          this.userDetails.contactNumber = response.data.contactNumber;
          this.userDetails.addressline1 = response.data.addressline1;
          this.userDetails.addressline2 = response.data.addressline2;
          this.userDetails.town = response.data.town;
          this.userDetails.county = response.data.county;
          this.userDetails.postcode = response.data.postcode;
          this.userDetails.file = response.data.file;
          this.userDetails.ukpsf = response.data.ukpsf;
          this.userDetails.cardpurchase = response.data.cardpurchasecount;
          this.userDetails.teamcaptain = response.data.teamcaptain;
          this.userDetails.disclaimer = response.data.disclaimer;

          var thisYearDivision = null;
          for (thisYearDivision in response.data.userHistory) {
            if (
              this.currentyear ===
              parseInt(response.data.userHistory[thisYearDivision].year)
            ) {
              this.selectdiv =
                response.data.userHistory[thisYearDivision].division.id;
              break;
            }
          }

          var currentTeam = null;
          for (currentTeam in response.data.userHistory) {
            if (
              this.currentyear ===
              parseInt(response.data.userHistory[currentTeam].year)
            ) {
              this.selectteam = response.data.userHistory[currentTeam].team.id;
              break;
            }
          }

          //          this.userDetails.divid = response.data.division.id;
          //          this.userDetails.teamid = response.data.team.id;
          this.loading = false;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    createOrder(data, actions) {
      return actions.order.create({
              purchase_units: [
                {
                  description: "UKPL Membership Card",
                  amount: { currency_code: "GBP", value: this.chargeValue },
                },
              ],
            });
    },
  },
  created() {
    this.initCurrentYear();
    this.initUser();
    this.isCaptain();
  },
  mounted() {
    loadScript({
      "client-id":
        process.env.NODE_ENV === "production"
          ? "AayKqfNsIRKcEEI8BspYWkvB-GnS6hr7kaQ0lUSJvKV1FfR5lDZtpUhLd007cYUYglzThQi2qe9I8xGN"
          : "ASPHXNm8FKPYa3evAt4K9tboaks_g44vwVrkdwXg-xgxZ8qFTq_2e2mTmAyjNrkk9P-uXPOq1VBYiaqB",
      currency: "GBP",
    }).then((paypal) => {
      paypal
        .Buttons({
          style: {
            shape: "pill",
            color: "blue",
            layout: "vertical",
            label: "paypal",
          },

          createOrder: this.createOrder,
          //function (data, actions) {
          //},

          onApprove: function (data, actions) {
            return actions.order.capture().then(function (orderData) {
              // Full available details
              console.log(
                "Capture result",
                orderData,
                JSON.stringify(orderData, null, 2)
              );

              // Show a success message within this page, e.g.
              const element = document.getElementById(
                "paypal-button-container"
              );
              element.innerHTML = "";
              element.innerHTML = "<h3>Thank you for your payment!</h3>";

              UserService.purchaseCard(orderData);
              this.initUser;
            });
          },

          onError: function (err) {
            console.log(err);
          },
        })
        .render("#paypal-button-container");
    });
  },
};
</script>
